// this helper function is specifically created to solve modal issues on phone
// issue occurs when you open modal which is 100vh and you have search bar on the bottom of the screen
import { isMobileDevice } from '../auth/helpers/deviceUtils';

export function setVH() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}

if (isMobileDevice()) {
    window.addEventListener('resize', setVH);
    setVH();
}
